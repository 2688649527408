<template>
  <v-app-bar id="app-bar" dense app transparent>
    <v-btn class="mr-3" text fab small @click="setDrawer(!drawer)">
      <v-icon> mdi-menu </v-icon>
    </v-btn>

    <v-toolbar-title> {{ $route.name }} </v-toolbar-title>
    <v-spacer />

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      class="ma-0"
    >
      <v-card class="ma-0">
        <v-row class="pa-4">
          <v-col cols="4"> </v-col>
          <v-col cols="8">
            <p class="body-2"></p>
          </v-col>
        </v-row>
        <v-card-actions class="grey lighten-4 text-rigth pa-2">
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-spacer />

    <v-menu min-width="200px" rounded>
      <template v-slot:activator="scope">
        <v-btn icon v-on="scope.on">
          <v-avatar size="36" color="#81D4FA">
            <v-img v-if="usuario.imagem" :src="usuario.imagem" />
            <span v-else class="text-h5">{{ usuario.name.substr(0, 1) }}</span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <div class="mx-auto text-center pb-4">
            <v-avatar
              size="36"
              color="#81D4FA"
              @click="abrirConfig"
              class="avatarCursos"
            >
              <v-img v-if="usuario.imagem" :src="usuario.imagem" />

              <span v-else class="text-h5">
                {{ usuario.name.substr(0, 1).toUpperCase() }}
              </span>
            </v-avatar>

            <h3>{{ usuario.name }}</h3>
            <p class="text-caption mt-1">
              {{ usuario.email }}
            </p>
            <v-divider class="my-3"></v-divider>
            <v-btn rounded link variant="text">
              <v-icon> mdi-door-open </v-icon>
              <v-list-item-content @click="logout">
                <v-list-item-title> Sair </v-list-item-title>
              </v-list-item-content>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import store from "@/store";
import empresas from "@/api/empresas";

export default {
  data: () => ({
    usuario: {
      name: "",
      email: "",
      imagem: null,
    },
  }),

  computed: {
    ...mapState("layout", ["drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "layout/SET_DRAWER",
    }),
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    show() {
      this.shouldShow = !this.shouldShow;
    },
    getUser() {
      let user = JSON.parse(localStorage.getItem("vuex"));
      this.usuario.name = user.usuario.usuario.nome;
      this.usuario.email = user.usuario.usuario.email;
    },

    abrirConfig() {
      this.$router.push(
        `/empresas/${store.state.usuario.usuario.empresa_id}/editar`
      );
    },
    async buscarLogo() {
      const resposta = await empresas.buscar(
        store.state.usuario.usuario.empresa_id
      );
      this.usuario.imagem = resposta.data.data.url_imagem;
    },
  },
  mounted() {
    this.getUser();
    this.buscarLogo();
  },
};
</script>

<style>
#app-bar {
  z-index: 2;
}
.avatarCursos {
  cursor: pointer;
}
</style>